import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import pfm from "../../Assets/Projects/pfm.png";
import sundaynights from "../../Assets/Projects/sundaynights.png"
import clippy from "../../Assets/Projects/clippy.png"

function Projects() {
  return (
    <Container fluid className="project-section" id="projects">
      <Particle />
      <Container>
        <h1 className="project-heading"  >
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sundaynights}
              isBlog={false}
              title="Sunday Nights"
              description="A poetry website with a curated collection of the best of English and Swahili poetry. It also serves as a blogging platform for poets and writers in the creative arts."
              ghLink="https://github.com/PeteJames21/sunday-nights"
              demoLink="https://sundaynights.blog"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pfm}
              isBlog={false}
              title="Personal Finance Tracker"
              description="A web application that allows users to track their personal finances and view statistical summaries and visualizations for any period."
              ghLink="https://github.com/PeteJames21/personal-finance-tracker"
              demoLink="https://pft.peterndungu.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={clippy}
              isBlog={false}
              title="Clippy"
              description="Clippy strives to streamline your coding experience by providing a centralized repository for commonly used code snippets. Its intuitive interface allows you to quickly add, retrieve, and share reusable items."
              ghLink="https://github.com/PeteJames21/clippy/"
              demoLink="https://clippy.peterndungu.tech/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
